<template>
    <div class="lll">
        <div ref="nodeOne" class="nodeOne">
            <zm-tree-org
                ref="tree"
                :draggable="false"
                :data="data"
                :disabled="disaled"
                :horizontal="horizontal"
                :collapsable="collapsable"
                :label-style="style"
                :node-draggable="true"
                :default-expand-level="99"
                :only-one-node="onlyOneNode"
                :clone-node-drag="cloneNodeDrag"
                @on-node-drag="nodeDragMove"
                @on-node-drag-end="nodeDragEnd"
                :toolBar="toolBar"
                :filterNodeMethod="filterNodeMethod"
                @on-expand="onExpand"
                @on-node-click="onNodeClick"
                @on-node-dblclick="onNodeDblclick"
                @on-node-copy="onNodeCopy"
                :before-drag-end="beforeDragEnd"
            >
            </zm-tree-org>
        </div>
    </div>
</template>
<script>
import allList from "../../../public/orgTree.json";
import { AppUser } from "./api/test";
export default {
    data() {
        return {
            toolBar: {
                scale: false,
            },
            data: {},
            horizontal: true, //横向展示
            collapsable: true, //是否可折叠
            onlyOneNode: false, //仅拖动当前节点
            cloneNodeDrag: false, //是否拷贝节点拖拽 false仅拖拽  true拷贝+保留原位
            expandAll: true, //展开所有节点
            disaled: true, //是否禁用编辑
            style: { color: "#fff", background: "#108ffe" },
        };
    },
    created() {
        new AppUser().getRoleIdMenu("969664765787897856", (data) => {
            console.log(data);

            this.data = this.addLabelToNodes(data.data);
        });
    },
    methods: {
        //递归添加label
        addLabelToNodes(data) {
            // 检查当前节点的username和roleNick，并创建label
            if (data.username && data.roleNick) {
                data.label = `${data.username}(${data.roleNick})`;
            } else if (data.username) {
                // 如果没有roleNick，只使用username
                data.label = data.username;
            }

            // 如果存在children，则递归处理每个子节点
            if (data.children && data.children.length > 0) {
                data.children.forEach((child) => this.addLabelToNodes(child));
            }

            return data;
        },
        //删除label
        removeLabelFromNodes(data) {
            // 如果节点存在label属性，删除它
            if ("label" in data) {
                delete data.label;
            }

            // 如果存在children，则递归处理每个子节点
            if (data.children && data.children.length > 0) {
                data.children.forEach((child) => removeLabelFromNodes(child));
            }

            return data;
        },
        //动态赋值
        terFun(node) {
            console.log(node);
        },
        //拖拽结束之前
        beforeDragEnd(node, targetNode) {
            console.log(node, targetNode, "拖拽结束之前");
            return new Promise((resolve, reject) => {
                if (!targetNode) reject();
                if (targetNode.roleType === 3) {
                    reject();
                } else {
                    resolve();
                }
            });
        },
        //右侧菜单点击事件
        onMenus({ node, command }) {},
        //自定义修改属性值
        editFun(data, node, val) {
            if (!data || !data.length) {
                return;
            }
            for (let i = 0; i < data.length; i++) {
                if (data[i].id === node.id) {
                    data[i].label = val;
                    break;
                }
                this.editFun(data[i].children, node, val);
            }
        },
        //确定 关闭car界面
        close(val) {
            this.$delete(val, "open");
            this.treeScope = false;
        },
        //颠倒编辑外区域，关闭
        closeO(event) {
            var currentCli = document.getElementById("lateId");
            if (currentCli) {
                if (!currentCli.contains(event.target)) {
                    this.treeScope = false;
                }
            }
        },

        filterNodeMethod(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        //展开事件
        onExpand(e, data) {},
        nodeDragMove(data) {
            //console.log("移动节点", data);
        },
        nodeDragEnd(data, isSelf) {
            // isSelf && this.$Message.info("移动到自身");
            console.log(this.data, "拖拽后结果");
        },
        //点击节点
        onNodeClick(e, data) {
            // this.$Message.info(data.label);
            console.log("点击节点");
            console.log(data);
        },
        onNodeDblclick(data) {
            // this.$Message.info("双击节点");
            console.log("双击节点");
            console.log(data);
        },
        onNodeCopy(data) {
            // this.$Message.success("复制成功");
            console.log("复制成功");
            console.log(data);
        },
        handleNodeAdd(node) {
            // this.$Message.info("新增节点");
            console.log("新增节点");
            console.log(node);
        },
        expandChange() {
            this.toggleExpand(this.data, this.expandAll);
        },
        //递归创建节点树
        toggleExpand(data, val) {
            if (Array.isArray(data)) {
                data.forEach((item) => {
                    this.$set(item, "expand", val);
                    if (item.children) {
                        this.toggleExpand(item.children, val);
                    }
                });
            } else {
                this.$set(data, "expand", val);
                if (data.children) {
                    this.toggleExpand(data.children, val);
                }
            }
        },
    },
};
</script>
<style scoped>
.pickers {
    display: flex;
}

.node {
    position: relative;
}

.nodeOne {
    height: 800px;
}

.late {
    position: absolute;
    top: 20px;
    right: -100px;
    width: 200px;
    min-height: 100px;
    z-index: 999;
    background: #f2e2be;
    padding: 10px;
}

.onCloseCss {
    width: 50px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    background: #108ffe;
    margin: 10px auto;
    z-index: 999;
}

::v-deep .is-edit {
    background: palegoldenrod !important;
}
</style>
