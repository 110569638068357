import { BasicsClass } from '@/common/BasicsClass';

export class AppUser {
    constructor() {
        this.getRoleIdMenu = (id, cb) => {
            new BasicsClass().BasicGet("/animal/web/appUserManagement/belowUserTree", { userId:id}, false, false, cb);
        };
    }
}

export class WebUser {
    constructor() {
        this.getLinkAppUser = (cb) => {
            new BasicsClass().BasicGet("/animal/web/appUserManagement/userBaseList335", {}, false, false, cb);
        };
    }
}
